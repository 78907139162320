var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _vm.errorMessage
        ? _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c("CAlert", { attrs: { color: "warning" } }, [
                _vm._v("Fehler: " + _vm._s(_vm.errorMessage))
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "CCol",
        { attrs: { md: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardBody",
                [
                  _c("ValidatedInput", {
                    attrs: {
                      label: "Name",
                      field: _vm.$v.playroom.name,
                      value: _vm.$v.playroom.name.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.playroom.name, "$model", $event)
                      }
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      label: "Provision",
                      field: _vm.$v.playroom.provisionPercentage,
                      value: _vm.$v.playroom.provisionPercentage.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.provisionPercentage,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      label: "Regular Tax Rate",
                      field: _vm.$v.playroom.regularTaxRate,
                      value: _vm.$v.playroom.regularTaxRate.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.regularTaxRate,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      label: "Reduced Tax Rate",
                      field: _vm.$v.playroom.reducedTaxRate,
                      value: _vm.$v.playroom.reducedTaxRate.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.reducedTaxRate,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("CInputCheckbox", {
                    attrs: {
                      checked: _vm.$v.playroom.activeByAdmin.$model,
                      label: "ActiveByAdmin"
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.activeByAdmin,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("CInputCheckbox", {
                    attrs: {
                      checked: _vm.$v.playroom.forceShowWebsiteLink.$model,
                      label: "Force Show WebsiteLink"
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.forceShowWebsiteLink,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("div", [
                    _c("dl", [
                      _c("dt", [_vm._v("Last Overnight Price Change")]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.formatIsoDateTimeTz(
                              _vm.playroom.lastOvernightPriceChange
                            )
                          )
                        )
                      ]),
                      _c("dt", [_vm._v("Last Hourly Price Change")]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.formatIsoDateTimeTz(
                              _vm.playroom.lastHourlyPriceChange
                            )
                          )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _vm._v(
            ' Vorsicht!: Mit diesem Button wird das Datum der Preis-Empfindliche Änderungen eines Playrooms auf "minimal time stamp" gesetzt. Damit kann ein Gastgeber wieder alle aktive Buchungen vor dieser Preisänderung bearbeiten. '
          ),
          _c(
            "CButton",
            {
              staticStyle: { width: "100%" },
              attrs: { value: "Reset last Price change", color: "danger" },
              on: { click: _vm.resetLastPriceChange }
            },
            [_vm._v(" Reset last Price change ")]
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "6" } },
        [
          _c(
            "CButton",
            {
              staticStyle: { width: "100%" },
              attrs: { value: "Speichern", color: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v(" Speichern ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }